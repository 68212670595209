import Vue from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faUserSecret,
  faPlug,
  faSquareParking,
  faTents,
  faRestroom,
  faShower,
  faWifi,
  faCreditCard,
  faLocationDot,
  faEnvelope,
  faPhone,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import VueCoreVideoPlayer from 'vue-core-video-player';
import store from './store';
import App from './App.vue';
import router from './router';

Vue.config.productionTip = false;
Vue.use(VueCoreVideoPlayer);

const isLangSet = localStorage.getItem('language');
if (!isLangSet) {
  localStorage.setItem('language', 'en');
  store.dispatch('setLanguage', 'en');
} else {
  store.dispatch('setLanguage', isLangSet);
}
library.add(
  faUserSecret,
  faPlug,
  faSquareParking,
  faTents,
  faRestroom,
  faShower,
  faWifi,
  faCreditCard,
  faLocationDot,
  faEnvelope,
  faPhone,
);
Vue.component('font-awesome-icon', FontAwesomeIcon);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
