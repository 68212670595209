export default {
  language: 'en',
  translations: {
    en: {
      home: 'Home',
      amenities: 'Services',
      contact: 'Contact Us',
      services: 'Services we offer',
      parkingSpaces: 'Parking spaces',
      tentArea: 'Tent area',
      toilet: 'Toilets',
      shower: 'Showers',
      wifi: 'Free WiFi',
      location: 'Location',
      priceList: 'Price List',
      pricesAday: 'Price per day',
      electricity: 'Electricity',
      ccAccept: 'We accept credit card payments',
      adults: 'Adults',
      children: 'Children 5-14',
      tent: 'Tent',
      car: 'Car',
      motorcycle: 'Motorcycle',
      van: 'Van',
      motorhome: 'Motorhome',
      intro: 'Located in Raštani near Mostar, our camp offers a unique vacation experience by the Neretva River. Enjoy the relaxing atmosphere with the soothing sound of the Neretva River.',
      welcome: 'Welcome to Camping Lora!',
    },
    hr: {
      home: 'Početna',
      amenities: 'Usluge',
      contact: 'Kontakt',
      parkingSpaces: 'Parking mjesta',
      services: 'Usluge koje nudimo',
      tentArea: 'Prostor za šatore',
      toilet: 'Toaleti',
      shower: 'Tuševi',
      wifi: 'Betsplatan WiFi',
      location: 'Lokacija',
      priceList: 'Cjenik',
      pricesAday: 'Cijena po danu',
      electricity: 'Struja',
      ccAccept: 'Prihvaćamo plaćanje kreditnim karticama',
      adults: 'Odrasli',
      children: 'Djeca 5-14',
      tent: 'Šator',
      car: 'Auto',
      motorcycle: 'Motor',
      van: 'Kombi',
      motorhome: 'Kamper',
      intro: 'Smješten u Raštanima pored Mostara, naš kamp nudi jedinstveno iskustvo odmora uz rijeku Neretvu. Uživajte u opuštajućoj atmosferi uz žubor rijeke Neretve.',
      welcome: 'Dobrodošli u Camping Lora!',
    },
  },
};
