<template>
  <div class="home">
    <div class="header-wrapper" ref="homeStart">
      <div v-if="!isMobile" class="video-wrapper">
        <vue-core-video-player
          :muted="true"
          :controls="false"
          :autoplay="true"
          :loop=true
          :height="'100vh'"
          src="./assets/WebHeaderVideo.mp4">
        </vue-core-video-player>
      </div>
      <div class="img-wrapper" v-if="isMobile">
        <img class="image" src="/assets/air-01.jpg" alt="">
      </div>
    </div>
    <div class="services-wrapper" ref="services">
      <h1>{{ welcome }}</h1>
      <h3 style="text-align: left; padding-left: 5px;">
        {{ intro }}</h3>
      <h1 class="label">{{ services }}</h1>
      <div class="card-wrapper">
        <div class="card">
          <div class="icon">
            <font-awesome-icon icon="square-parking" size="4x"/>
          </div>
          <div class="description">
            {{ parkingSpaces }}
          </div>
        </div>
        <!-- <div class="card">
          <div class="icon">
            <font-awesome-icon icon="tents" size="4x"/>
          </div>
          <div class="description">
            {{ tentArea }}
          </div>
        </div> -->
        <div class="card">
          <div class="icon">
            <font-awesome-icon icon="plug" size="4x"/>
          </div>
          <div class="description">
            {{ electricity }}
          </div>
        </div>
        <div class="card">
          <div class="icon">
            <font-awesome-icon icon="restroom" size="4x"/>
          </div>
          <div class="description">
            {{ toilet }}
          </div>
        </div>
        <div class="card">
          <div class="icon">
            <font-awesome-icon icon="shower" size="4x"/>
          </div>
          <div class="description">
            {{ shower }}
          </div>
        </div>
        <div class="card">
          <div class="icon">
            <font-awesome-icon icon="wifi" size="4x"/>
          </div>
          <div class="description">
            {{ wifi }}
          </div>
        </div>
      </div>
    </div>
    <div class="location-wrapper" ref="location">
      <h1 class="label">{{ location }}</h1>
      <div class="map-wrapper">
        <iframe class="gembed" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1788.7556590761928!2d17.8309756524007!3d43.36733526080212!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x134b45fccf4f199d%3A0x1ca797f8a19feda6!2sCamping%20Lora!5e0!3m2!1sen!2sba!4v1720299331703!5m2!1sen!2sba" width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
      <div class="row-wrapper">
        <div class="left">
          <img src="/assets/air-01.jpg" alt="">
        </div>
        <div class="right">
          <img src="/assets/air-03.jpg" alt="">
        </div>
      </div>
    </div>
    <div class="price-wrapper" ref="price">
      <h1 class="label">{{ priceList }}</h1>
      <div class="table-wrapper">
        <div class="table-row">
          <div class="table-left">{{ daily }}</div>
          <div class="table-middle">BAM</div>
          <div class="table-right">~ EUR</div>
        </div>
        <div class="table-row">
          <div class="table-left">{{ adults }}</div>
          <div class="table-middle">10</div>
          <div class="table-right">5</div>
        </div>
        <div class="table-row">
          <div class="table-left">{{ children}}</div>
          <div class="table-middle">5</div>
          <div class="table-right">2.5</div>
        </div>
        <div class="table-row">
          <div class="table-left">{{ tent }}</div>
          <div class="table-middle">10</div>
          <div class="table-right">5</div>
        </div>
        <div class="table-row">
          <div class="table-left">{{ car }}</div>
          <div class="table-middle">10</div>
          <div class="table-right">5</div>
        </div>
        <!-- <div class="table-row">
          <div class="table-left">{{ motorcycle }}</div>
          <div class="table-middle">5</div>
          <div class="table-right">2.5</div>
        </div> -->
        <div class="table-row">
          <div class="table-left">{{ van }}</div>
          <div class="table-middle">20</div>
          <div class="table-right">10</div>
        </div>
        <div class="table-row">
          <div class="table-left">{{ motorhome }}</div>
          <div class="table-middle">30</div>
          <div class="table-right">15</div>
        </div>
        <br>
        <div style="color: green;">{{ ccAccept }}</div>
        <font-awesome-icon style="color: green;" icon="credit-card" size="4x"/>
        <br>
        <br>
      </div>
    </div>
    <div class="contact-wrapper" ref="contact">
      <h1 style="padding-top: 10px;" class="label">{{ contact }}</h1>
      <br>
      <div>
        <font-awesome-icon style="color: white;" icon="location-dot" size="1x"/>
        <span style="padding-left: 5px;">RAŠTANI B.B., Mostar, 88000 Mostar</span>
      </div>
      <br>
      <div>
        <font-awesome-icon style="color: white;" icon="envelope" size="1x"/>
        <span style="padding-left: 5px;">camping.lora@gmail.com</span>
      </div>
      <br>
      <div>
        <font-awesome-icon style="color: white;" icon="phone" size="1x"/>
        <span style="padding-left: 5px;">+387 63 311 044</span>
      </div>
      <br>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Home',
  data() {
    return {
      controls: {

      },
    };
  },
  computed: {
    ...mapGetters([
      'language',
      'translations',
    ]),
    isMobile() {
      return window.innerWidth < 600;
    },
    services() {
      return this.translations[this.language].services;
    },
    parkingSpaces() {
      return this.translations[this.language].parkingSpaces;
    },
    tentArea() {
      return this.translations[this.language].tentArea;
    },
    toilet() {
      return this.translations[this.language].toilet;
    },
    shower() {
      return this.translations[this.language].shower;
    },
    wifi() {
      return this.translations[this.language].wifi;
    },
    location() {
      return this.translations[this.language].location;
    },
    priceList() {
      return this.translations[this.language].priceList;
    },
    daily() {
      return this.translations[this.language].pricesAday;
    },
    contact() {
      return this.translations[this.language].contact;
    },
    electricity() {
      return this.translations[this.language].electricity;
    },
    ccAccept() {
      return this.translations[this.language].ccAccept;
    },
    adults() {
      return this.translations[this.language].adults;
    },
    children() {
      return this.translations[this.language].children;
    },
    tent() {
      return this.translations[this.language].tent;
    },
    car() {
      return this.translations[this.language].car;
    },
    motorcycle() {
      return this.translations[this.language].motorcycle;
    },
    van() {
      return this.translations[this.language].van;
    },
    motorhome() {
      return this.translations[this.language].motorhome;
    },
    intro() {
      return this.translations[this.language].intro;
    },
    welcome() {
      return this.translations[this.language].welcome;
    },
  },
  methods: {
    scrollToHome2() {
      const target = this.$refs.homeStart;
      if (target) {
        target.scrollIntoView({ behavior: 'smooth' });
      }
    },
    scrollToServices() {
      const target = this.$refs.services;
      if (target) {
        target.scrollIntoView({ behavior: 'smooth' });
      }
    },
    scrollToLocation() {
      const target = this.$refs.location;
      if (target) {
        target.scrollIntoView({ behavior: 'smooth' });
      }
    },
    scrollToPrice() {
      const target = this.$refs.price;
      if (target) {
        target.scrollIntoView({ behavior: 'smooth' });
      }
    },
    scrollToContact() {
      const target = this.$refs.contact;
      if (target) {
        target.scrollIntoView({ behavior: 'smooth' });
      }
    },
  },
  mounted() {
    this.$root.$on('scroll-to-home', () => {
      this.scrollToHome2();
    });
    this.$root.$on('scroll-to-services', () => {
      this.scrollToServices();
    });
    this.$root.$on('scroll-to-location', () => {
      this.scrollToLocation();
    });
    this.$root.$on('scroll-to-prices', () => {
      this.scrollToPrice();
    });
    this.$root.$on('scroll-to-contact', () => {
      this.scrollToContact();
    });
  },
};
</script>

<style lang="scss">

.home {
  .header-wrapper {
    .video-wrapper {
      .vcp-container {
        background-color: #fff;
      }
    }
    .img-wrapper {
      .image {
        padding-top: 120px;
        width: 100%;
        object-fit: cover;
        object-position: 25% 25%;
      }
    }
  }
  .services-wrapper {
    display: flex;
    flex-direction: column;
    //  background-color: #3E8787;
    //  background-color: #F15A2B;
    background-color: #fff;
    .left {
      width: 40%;
    }
    .card-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      max-width: 1000px;
      width: 100%;
      margin: 0 auto;
      .card {
        margin: 10px;
        border-radius: 7px;
        border: 2px solid #183153;
        padding: 15px;
        width: 100px;
        background-color: #fff;
        .icon {
          color: rgb(24,49,83);
        }
        .description {
          padding-top: 10px;
        }
      }
    }
  }
  .location-wrapper {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    .map-wrapper {
      height: 450px;
    }
    .label {
      color: #000;
    }
    .row-wrapper {
      width: 100%;
      display: flex;
      .left {
        margin: 4px;
        width: 50%;
        img {
          width: 100%;
          border-radius: 8px;
        }
      }
      .right {
        margin: 4px;
        width: 50%;
        position: relative;
        img {
          width: 100%;
          border-radius: 8px;
        };
      }
    }
  }
  .price-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    .table-wrapper {
      max-width: 600px;
      width: 100%;
      font-size: 20px;
      .table-row {
        //  background-color: rgba(#F15A2B, .5);
        border-top: 1px solid rgb(24,49,83);
        border-bottom: 1px solid rgb(24,49,83);
        display: flex;
        &:first-child {
          background-color: rgba(24,49,83, .2);
        }
        .table-left {
          max-width: 500px;
          width: 100%;
          text-align: left;
          padding-left: 8px;
        }
        .table-middle {
          width: 100px;
          text-align: center;
        }
        .table-right {
          width: 100px;
        }
      }
    }
  }
  .contact-wrapper {
    font-size: 18px;
    background-color: #000;
    color: #fff;
    text-align: left;
    padding-left: 10px;
    .label {
      text-align: center;
      padding: 0;
      margin: 0;
    }
  }
}
</style>
