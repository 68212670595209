<template>
  <div id="app">
    <!-- <div class="welcome-wrapper">Welcome to Camping Lora</div> -->
    <div id="nav" class="navigation">
      <div class="menu">
        <div class="button-wrapper">
          <div v-if="!isMobile" class="button" @click="emitToHome">{{ homeLabel }}</div>
          <div v-if="!isMobile" class="button" @click="emitToServices">{{ amenitiesLabel }}</div>
          <div v-if="!isMobile" class="button" @click="emitToLocation">{{ locationLabel }}</div>
          <div v-if="!isMobile" class="button" @click="emitToPrices">{{ priceListLabel }}</div>
          <div v-if="!isMobile" class="button" @click="emitToContact">{{ contactLabel }}</div>

        </div>
        <div class="language-bar">
          <div class="lang" @click="setLangTo('en')">
            <div class="lang-overlay" :class="{ active: language === 'en' }"></div>
            <img class="flag" src="/assets/united_kingdom_64.png" alt="">
          </div>
          <div class="lang" @click="setLangTo('hr')">
            <div class="lang-overlay" :class="{ active: language === 'hr' }"></div>
            <img class="flag" src="/assets/croatia_64.png" alt="">
          </div>
        </div>
      </div>
      <div class="logo">
        <img src="../public/assets/Lora-logo2.png"
        alt="Camping Lora"
        style="width: 100px;">
      </div>
      <!-- <div>HOME</div>
      <div>CONTACT</div>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">Contact</router-link> -->
    </div>
    <router-view/>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'Home',
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters([
      'language',
      'translations',
    ]),
    isMobile() {
      return window.innerWidth < 600;
    },
    homeLabel() {
      return this.translations[this.language].home.toUpperCase();
    },
    amenitiesLabel() {
      return this.translations[this.language].amenities.toUpperCase();
    },
    contactLabel() {
      return this.translations[this.language].contact.toUpperCase();
    },
    locationLabel() {
      return this.translations[this.language].location.toUpperCase();
    },
    priceListLabel() {
      return this.translations[this.language].priceList.toUpperCase();
    },
  },
  methods: {
    ...mapActions([
      'setLanguage',
    ]),
    setLangTo(lang) {
      this.setLanguage(lang);
    },
    emitToHome() {
      this.$root.$emit('scroll-to-home');
    },
    emitToServices() {
      this.$root.$emit('scroll-to-services');
    },
    emitToLocation() {
      this.$root.$emit('scroll-to-location');
    },
    emitToPrices() {
      this.$root.$emit('scroll-to-prices');
    },
    emitToContact() {
      this.$root.$emit('scroll-to-contact');
    },
  },
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

html,
body {
  background-color: #fff;
  margin: 0;
}
#app {
  position: relative;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  scrollbar-width: none !important;
  overflow-y: scroll;
  height: 100vh;
}

#nav {
  position: fixed;
  display: flex;
  //  justify-content: space-between;
  z-index: 200;
  padding: 5px 30px;
  .logo {
    z-index: 200;
  }
  .menu {
    position: absolute;
    display: flex;
    padding-left: 150px;
    width: 100vw;
    height: 60px;
    top: 30px;
    left: 0;
    z-index: 199;
    background-color: rgba(#fff, .9);
    .button-wrapper {
      display: flex;
      justify-content: space-between;
    }
    .button {
      background-color: rgba(#ef5c2b, 0);
      width: 120px;
      height: 60px;
      margin: 0 5px;
      color: #000;
      line-height: 60px;
      cursor: pointer;
      user-select: none;
      font-weight: bold;
      &:hover {
        transition: all .5s ease;
        background-color: rgba(#ef5c2b, 1);
        color: #fff;
      }
    }
    .language-bar {
      position: absolute;
      display: flex;
      right: 170px;
      top: 5px;
      .lang {
        cursor: pointer;
        position: relative;
        .flag {
          //  position: absolute;
          //  top: 50%;
          //  left: 50%;
          //  transform: translate(-50%, -50%);
        }
        .lang-overlay {
          position: absolute;
          background-color: #fff;
          width: 48px;
          height: 48px;
          opacity: 0.8;
          border-radius: 100%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          &.active {
            display: none;
          }
        }
      }
    }
  }

  a {
    font-weight: bold;
    //  color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
