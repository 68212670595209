import Vue from 'vue';
import Vuex from 'vuex';
import { assign } from 'lodash';
import storeConfig from './storeConfig';

const store = assign(storeConfig);

Vue.use(Vuex);

export default new Vuex.Store(store);
